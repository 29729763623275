<template>
    <div id="UserProfile">
        <v-container fluid app class="pa-2 pa-lg-6">
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title class="headline light--text"
                            :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                        >
                            {{ $vuetify.breakpoint.smAndDown ? $vuetify.lang.t('$vuetify.userInformationxs') : $vuetify.lang.t('$vuetify.userInformation') }}

                            <v-spacer></v-spacer>
                            
                            <v-tooltip top>
                                <template v-slot:activator="{on}">
                                    <v-btn v-on="on"
                                        class="pa-0"
                                        @click="$router.push('/')"
                                        min-width="48px"
                                        text dark
                                    >
                                        <v-icon color="light">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Fechar</span>
                            </v-tooltip>
                        </v-card-title>

                        <validation-observer v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(save)">
                                <v-row no-gutters>
                                    <v-col xs="12" sm="2" md="2" lg="2" xl="2" class="ma-7">
                                
                                        <!-- PHOTO -->
                                        <v-container class="pa-0">
                                            <v-img v-if="user.photo || photoPreview"
                                                :src="photoPreview ? photoPreview : user.photo"
                                                :alt="'Foto de ' + user.name"
                                                :aspect-ratio="1/1"
                                                style="border-radius: 50%;"
                                            />

                                            <svg v-else style="width:100%; height:100%" viewBox="0 0 24 24">
                                                <path :fill="$vuetify.theme.themes.light.primary" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                                            </svg>

                                            <div class="text-center mt-3">
                                                <v-btn
                                                    plain
                                                    color="primary"
                                                    class="text-none"
                                                    @click="openFileInput"
                                                >
                                                    <v-icon left>
                                                        mdi-camera
                                                    </v-icon>
                                                    {{ user.photo || photoPreview ? $vuetify.lang.t('$vuetify.change') : $vuetify.lang.t('$vuetify.add') }}
                                                </v-btn>

                                                <v-btn v-if="user.photo || photoPreview"
                                                    plain
                                                    color="primary"
                                                    class="text-none"
                                                    @click="removePhoto"
                                                >
                                                    <v-icon left>
                                                        mdi-delete
                                                    </v-icon>
                                                    {{ $vuetify.lang.t('$vuetify.remove') }}
                                                </v-btn>

                                                <v-file-input ref="fileInput"
                                                    :clearable="false"
                                                    @change="changePhoto"
                                                    v-model="photo"
                                                    prepend-icon="mdi-camera"
                                                    style="display: none;"
                                                >
                                                </v-file-input>
                                            </div>
                                        </v-container>
                                    </v-col>

                                    <v-col class="pa-7">

                                        <!-- NAME -->
                                        <validation-provider
                                            :rules="{required: true}"
                                            v-slot="{errors}"
                                            :name="$vuetify.lang.t('$vuetify.name')"
                                        >
                                            <v-text-field color="primary"
                                                type="text"
                                                :label="`${$vuetify.lang.t('$vuetify.name')} *`"
                                                prepend-icon="mdi-account"
                                                v-model="user.name"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                        

                                        <!-- EMAIL -->
                                        <div class="mt-5">
                                            <validation-provider
                                                :rules="{required: true, email: true}"
                                                v-slot="{errors}"
                                                :name="$vuetify.lang.t('$vuetify.primaryEmail')"
                                            >
                                                <v-text-field color="primary"
                                                    type="text"
                                                    :label="`${$vuetify.lang.t('$vuetify.primaryEmail')} *`"
                                                    prepend-icon="mdi-email"
                                                    v-model="user.email"
                                                    :error-messages="errors"
                                                ></v-text-field>
                                            </validation-provider>

                                            <v-expand-transition>
                                                <div v-if="showEmail2" class="d-flex">
                                                    <validation-provider
                                                        :rules="{ email: true }"
                                                        v-slot="{errors}"
                                                        :name="'E-mail 2'"
                                                        tag="div"
                                                        style="width: 100%;"
                                                        class="mr-2"
                                                    >
                                                        <v-text-field 
                                                            color="primary"
                                                            type="text"
                                                            label="E-mail 2"
                                                            prepend-icon="mdi-email"
                                                            v-model="user.email2"
                                                            :error-messages="errors"
                                                        ></v-text-field>
                                                    </validation-provider>

                                                    <div class="d-flex align-center"
                                                        @click="removeEmail(2)"
                                                    >
                                                        <v-btn x-small plain color="white" class="pa-0 ma-0">
                                                            <v-icon color="primary">
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-expand-transition>

                                            <v-expand-transition>
                                                <div v-if="showEmail3" class="d-flex">
                                                    <validation-provider
                                                        :rules="{ email: true }"
                                                        v-slot="{errors}"
                                                        :name="'E-mail 3'"
                                                        tag="div"
                                                        style="width: 100%;"
                                                        class="mr-2"
                                                    >
                                                        <v-text-field 
                                                            color="primary"
                                                            type="text"
                                                            label="E-mail 3"
                                                            prepend-icon="mdi-email"
                                                            v-model="user.email3"
                                                            :error-messages="errors"
                                                        ></v-text-field>
                                                    </validation-provider>

                                                    <div class="d-flex align-center"
                                                        @click="removeEmail(3)"
                                                    >
                                                        <v-btn x-small plain color="white" class="pa-0 ma-0">
                                                            <v-icon color="primary">
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-expand-transition>
                                            
                                            <v-fade-transition>
                                                <div class="ml-4" style="margin-top: -8px;">
                                                    <v-btn v-if="!showEmail3"
                                                        small plain text color="white"
                                                        @click="addEmail"
                                                    >
                                                        <span class="primary--text">
                                                            {{ $vuetify.breakpoint.smAndDown ? '+ ' + $vuetify.lang.t('$vuetify.addAlternateEmailxs') : '+ ' + $vuetify.lang.t('$vuetify.addAlternateEmail') }}
                                                        </span>
                                                    </v-btn>
                                                </div>
                                            </v-fade-transition>
                                        </div>
                                        
                                        <!-- PHONE -->
                                        <div class="mt-5">
                                            <div class="d-flex align-center">
                                                <v-icon class="mr-2">
                                                    mdi-phone
                                                </v-icon>

                                                <VueCountryCode :key="phoneKey"
                                                    @onSelect="onCountrySelect($event, 1)"
                                                    :defaultCountry="phoneCountryCode"
                                                    :preferredCountries="['br', 'us', 'es']"
                                                    :enabledCountryCode="true"
                                                    class="mr-2 mb-2"
                                                >
                                                </VueCountryCode>

                                                <v-text-field color="primary"
                                                    type="text"
                                                    :label="$vuetify.lang.t('$vuetify.primaryPhone')"
                                                    v-model="user.phone"
                                                ></v-text-field>  
                                            </div>

                                            <v-expand-transition>
                                                <div v-if="showPhone2" class="d-flex align-center">
                                                    <v-icon class="mr-2">
                                                        mdi-phone
                                                    </v-icon>

                                                    <VueCountryCode :key="phone2Key"
                                                        @onSelect="onCountrySelect($event, 2)"
                                                        :defaultCountry="phone2CountryCode"
                                                        :preferredCountries="['br', 'us', 'es']"
                                                        :enabledCountryCode="true"
                                                        class="mr-2 mb-2"
                                                    >
                                                    </VueCountryCode>
                                                    
                                                    <v-text-field
                                                        color="primary"
                                                        type="text"
                                                        :label="$vuetify.lang.t('$vuetify.phone') + ' 2'"
                                                        v-model="user.phone2"
                                                        class="mr-2"
                                                    ></v-text-field>

                                                    <div class="d-flex align-center"
                                                        @click="removePhone(2)"
                                                    >
                                                        <v-btn x-small plain color="white" class="pa-0 ma-0">
                                                            <v-icon color="primary">
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-expand-transition>

                                            <v-expand-transition>
                                                <div v-if="showPhone3" class="d-flex align-center">
                                                    <v-icon class="mr-2">
                                                        mdi-phone
                                                    </v-icon>

                                                    <VueCountryCode :key="phone3Key"
                                                        @onSelect="onCountrySelect($event, 3)"
                                                        :defaultCountry="phone3CountryCode"
                                                        :preferredCountries="['br', 'us', 'es']"
                                                        :enabledCountryCode="true"
                                                        class="mr-2 mb-2"
                                                    >
                                                    </VueCountryCode>

                                                    <v-text-field
                                                        color="primary"
                                                        type="text"
                                                        :label="$vuetify.lang.t('$vuetify.phone') + ' 3'"
                                                        v-model="user.phone3"
                                                        class="mr-2"
                                                    ></v-text-field>

                                                    <div class="d-flex align-center"
                                                        @click="removePhone(3)"
                                                    >
                                                        <v-btn x-small plain color="white" class="pa-0 ma-0">
                                                            <v-icon color="primary">
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                </div>
                                            </v-expand-transition>

                                            <div class="ml-4" style="margin-top: -8px;">
                                                <v-btn v-if="!showPhone3"
                                                    small plain text color="white"
                                                    @click="addPhone"
                                                >
                                                    <span class="primary--text">
                                                        {{ $vuetify.breakpoint.smAndDown ? '+ ' + $vuetify.lang.t('$vuetify.addAlternatePhonexs') : '+ ' + $vuetify.lang.t('$vuetify.addAlternatePhone') }}
                                                    </span>
                                                </v-btn>
                                            </div>
                                        </div>

                                        <!-- EXTENSION -->
                                        <v-text-field color="primary"
                                            type="text"
                                            :label="$vuetify.lang.t('$vuetify.extension')"
                                            prepend-icon="mdi-deskphone"
                                            v-model="user.extension"
                                            class="mt-5"
                                        ></v-text-field>

                                        <!-- SMS -->
                                        <div class="mt-5">
                                            <div class="d-flex align-center">
                                                <v-icon class="mr-2">
                                                    mdi-message-alert
                                                </v-icon>

                                                <VueCountryCode :key="smsKey"
                                                    @onSelect="onCountrySelect($event, 4)"
                                                    :defaultCountry="smsCountryCode"
                                                    :onlyCountries="['br', 'us', 'es']"
                                                    :enabledCountryCode="true"
                                                    class="mr-2 mb-2"
                                                >
                                                </VueCountryCode>

                                                <v-text-field color="primary"
                                                    type="text"
                                                    :label="$vuetify.breakpoint.smAndDown ? $vuetify.lang.t('$vuetify.smsCellphonexs') : $vuetify.lang.t('$vuetify.smsCellphone')"
                                                    v-model="user.sms"
                                                ></v-text-field>
                                            </div>

                                            <div class="d-flex flex-column" v-if="user.phone">
                                                <p class="ma-0 text-caption primary--text">{{ $vuetify.lang.t('$vuetify.chooseRegisteredPhone') }}</p>

                                                <v-radio-group
                                                    :row="$vuetify.breakpoint.smAndDown ? false : true"
                                                    :column="$vuetify.breakpoint.smAndDown ? true : false"
                                                    v-model="user.sms"
                                                >
                                                    <v-radio
                                                        :label="$vuetify.lang.t('$vuetify.primaryPhone')"
                                                        :value="user.phone ? user.phone : ''"
                                                        @click="changeSmsCountryCode(1)"
                                                        class="mr-3"
                                                    ></v-radio>
                                                    <v-radio v-if="user.phone2"
                                                        :label="$vuetify.lang.t('$vuetify.phone') + ' 2'"
                                                        :value="user.phone2"
                                                        @click="changeSmsCountryCode(2)"
                                                        class="mr-3"
                                                    ></v-radio>
                                                    <v-radio v-if="user.phone3"
                                                        :label="$vuetify.lang.t('$vuetify.phone') + ' 3'"
                                                        :value="user.phone3"
                                                        @click="changeSmsCountryCode(3)"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </div>
                                        </div>

                                        <!-- PASSWORD EXPIRATION_AT -->
                                        <v-text-field color="primary"
                                            type="text"
                                            readonly
                                            :label="$vuetify.lang.t('$vuetify.passwordExpirationDate')"
                                            prepend-icon="mdi-calendar-alert"
                                            v-model="passwordExpiration"
                                            class="mt-5"
                                        ></v-text-field>

                                        <div class="mt-7 text-caption text-end">
                                            <strong>{{ $vuetify.lang.t('$vuetify.lastUpdate') + ':' }}</strong> {{ lastUpdate }}
                                        </div>

                                        <div class="text-center mt-7"
                                            :class="$vuetify.breakpoint.smAndDown ? 'd-flex pa-0 justify-center' : ''"
                                        >
                                            <v-btn outlined color="primary"
                                                :class="$vuetify.breakpoint.smAndDown ? 'mr-4' : 'mr-7'"
                                                @click="dialog = true"
                                                :large="$vuetify.breakpoint.xs ? false : true"
                                            >
                                                {{ $vuetify.lang.t('$vuetify.changePassword') }}
                                            </v-btn>

                                            <v-btn color="primary" type="submit"
                                                key="save"
                                                :loading="loading"
                                                :large="$vuetify.breakpoint.xs ? false : true"
                                                :disabled="disableSave"
                                            >
                                                {{ $vuetify.breakpoint.xs ? $vuetify.lang.t('$vuetify.save') : $vuetify.lang.t('$vuetify.saveChanges') }}
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row> 
                            </form>
                        </validation-observer>  
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <PasswordDialog
            :dialog="dialog"
            :user="user"
            @closePasswordDialog="dialog = false"
        />
    </div>
</template>

<script>
import lodash from 'lodash'
import PasswordDialog from '@/components/PasswordDialog'
import VueCountryCode from "vue-country-code"
import { mapMutations } from 'vuex'

export default {
    name:"UserProfile",

    components: { PasswordDialog, VueCountryCode },
    
    data: () => ({
        user: {},
        photoPreview: null,
        photo: '',
        lastUpdate: '',
        passwordExpiration: '',
        showEmail2: false,
        showEmail3: false,
        showPhone2: false,
        showPhone3: false,
        phoneCountryCode: 'br',
        phoneKey: 0,
        phone2CountryCode: 'br',
        phone2Key: 0,
        phone3CountryCode: 'br',
        phone3Key: 0,
        smsCountryCode: 'br',
        smsKey: 0,
        dialog: false,
        loading: false,
    }),

    computed: {
        disableSave() {
            return (!this.user.name || !this.user.email) ? true : false
        }
    },

    created() {
        let user = lodash.cloneDeep(this.$store.getters['auth/getUser'])

        let updatedAt = null

        if (user.updated_at === null) {
            updatedAt = new Date(user.created_at)
        } else {
            updatedAt = new Date(user.updated_at)
        }

        this.lastUpdate = this.formatDate(updatedAt)

        let passwordExpiration = new Date(user.expiration_at)
        this.passwordExpiration = this.formatDate(passwordExpiration)

        // EMAIL 2
        let email2 = user.email2
        if (email2 === "") {
            this.showEmail2 = false
        } else {
            this.showEmail2 = true
        }
        
        // EMAIL 3
        let email3 = user.email3
        if (email3 === "") {
            this.showEmail3 = false
        } else {
            this.showEmail3 = true
        }

        // PHONE
        if (user.phone_country_code) {
            this.phoneCountryCode = user.phone_country_code
            this.phoneKey += 1
        }

        // PHONE 2
        if (user.phone2) {
            this.showPhone2 = true
            this.phone2CountryCode = user.phone2_country_code
            this.phone2Key += 1
        } else {
            this.showPhone2 = false
        }

        // PHONE 3
        if (user.phone3) {
            this.showPhone3 = true
            this.phone3CountryCode = user.phone3_country_code
            this.phone3Key += 1
        } else {
            this.showPhone3 = false
        }

        // SMS
        if (user.sms) {
            this.smsCountryCode = user.sms_country_code
            this.smsKey += 1
        }

        this.user = user
    },

    methods: {
        ...mapMutations('auth', ['setUser']),

        openFileInput() {
            this.$refs.fileInput.$refs.input.click()
        },

        changePhoto() {
            this.photoPreview = URL.createObjectURL(this.photo)
        },

        removePhoto() {
            this.photo = null,
            this.user.photo = ''
            this.photoPreview = null
        },

        addEmail() {
            if (this.showEmail2 === false && this.showEmail3 === false) {
                this.showEmail2 = true
            } else {
                this.showEmail3 = true
            }
        },

        removeEmail(emailId) {
            if (emailId === 2) {
                if (this.showEmail3 === true) {
                    this.user.email2 = this.user.email3
                    this.showEmail3 = false
                    this.user.email3 = ''
                } else {
                    this.showEmail2 = false
                    this.user.email2 = ''
                }
            }

            if (emailId === 3) {
                this.showEmail3 = false
                this.user.email3 = ''
            }
        },

        addPhone() {
            if (this.showPhone2 === false && this.showPhone3 === false) {
                this.showPhone2 = true
            } else {
                this.showPhone3 = true
            }
        },

        removePhone(phoneId) {
            if (phoneId === 2) {
                if (this.showPhone3 === true) {
                    this.user.phone2 = this.user.phone3
                    this.showPhone3 = false
                    this.user.phone3 = ''
                    this.phone3CountryCode = ''
                } else {
                    this.showPhone2 = false
                    this.user.phone2 = ''
                    this.phone2CountryCode = ''
                }
            }

            if (phoneId === 3) {
                this.showPhone3 = false
                this.user.phone3 = ''
                this.phone3CountryCode = ''
            }
        },

        onCountrySelect(e, phoneId) {
            if (phoneId === 1) {
                this.phoneCountryCode = e.iso2
            } else if (phoneId === 2) {
                this.phone2CountryCode = e.iso2
            } else if (phoneId === 3) {
                this.phone3CountryCode = e.iso2
            } else if (phoneId === 4) {
                this.smsCountryCode = e.iso2
            }
        },

        changeSmsCountryCode(phoneId) {
            if (phoneId === 1) {
                this.smsCountryCode = this.phoneCountryCode
            } else if (phoneId === 2) {
                this.smsCountryCode = this.phone2CountryCode
            } if (phoneId === 3) {
                this.smsCountryCode = this.phone3CountryCode
            }

            this.smsKey += 1
        },            

        twoDigitsNumber(number) {
            return number < 10 ? '0' + number : '' + number
        },

        formatDate(date) {
            let day = date.getDate()
            let twoDigitsDay = this.twoDigitsNumber(day)

            let month = date.getMonth() + 1
            let twoDigitsMonth = this.twoDigitsNumber(month)

            let hours = date.getHours()
            let twoDigitsHours = this.twoDigitsNumber(hours)

            let minutes = date.getMinutes()
            let twoDigitsMinutes = this.twoDigitsNumber(minutes)

            return twoDigitsDay + '/' +
                twoDigitsMonth + '/' +
                date.getFullYear() + ' - ' +
                twoDigitsHours + ':' +
                twoDigitsMinutes
        },

        async save() {
            this.loading = true

            let photo = null
            if (this.photo) {
                photo = this.photo
            } else {
                photo = this.user.photo
            }

            this.user.photo = photo
            let user = this.user

            let formData = new FormData()
                formData.append('name', user.name)
                formData.append('email', user.email)
                formData.append('email2', user.email2)
                formData.append('email3', user.email3)
                formData.append('photo', user.photo)
                formData.append('phone_country_code', this.phoneCountryCode)
                formData.append('phone', user.phone)
                formData.append('phone2_country_code', this.phone2CountryCode)
                formData.append('phone2', user.phone2)
                formData.append('phone3_country_code', this.phone3CountryCode)
                formData.append('phone3', user.phone3)
                formData.append('extension', user.extension)
                formData.append('sms_country_code', this.smsCountryCode)
                formData.append('sms', user.sms)

            try {
                let res = await this.$http.put(this.$ipUser + 'user/edit/' + this.user.id_user,
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }
                )

                if (res) {
                    let userListRes = await this.$http.post(this.$ipUser + `user/list/${res.data.id[0]}`, {})
                    if (userListRes) {
                        this.setUser(userListRes.data.rows[0])
                        this.$toast.success(res.data.msg)
                        this.loading = false
                        this.$router.push('/')
                    }
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },
    },
}
</script>

<style>
    .fade-enter {
		opacity: 0;
	}

	.fade-enter-active {
		transition: opacity .28s ease;
	}

	.fade-leave-active {
		transition: opacity .28s ease;
		opacity: 0;
	}
    .profile-pic{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .profile-pic-wrapper{
        position: relative;
    }
    .black-overlay {
        position: absolute;
        z-index: 100;
        width:100%; height:100%;
        background:rgba(0,0,0,0.6);
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
    }
    .card-profile-name{
        background-color: #feb650!important
    }

    /* VUE COUNTRY CODE */
    .vue-country-select {
        border-radius: 3px;
        display: inline-block;
        border: none !important;
        border-bottom: 1px solid rgb(118, 118, 118) !important;
        text-align: left;
    }

    .vue-country-select:hover {
        border-bottom: 1px solid black !important;
    }

    .vue-country-select .country-code {
        color: #666;
        padding: 0 4px 0 2px;
    }

    .vue-country-select:focus-within {
        box-shadow: none !important;
    }
</style>