<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
            @click:outside="closePasswordDialog"
        >
            <v-card>
                <v-card-title class="headline neutral">
                    Alterar senha

                    <v-spacer></v-spacer>

                    <v-icon @click="closePasswordDialog">
                        mdi-close
                    </v-icon>
                </v-card-title>

                <validation-observer v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(changePassword)">
                        <v-card-text>
                            <v-container>
                                <validation-provider
                                    :rules="{required: true}"
                                    v-slot="{errors}"
                                    name="Senha antiga"
                                >
                                    <v-text-field color="primary"
                                        label="Senha antiga"
                                        prepend-icon="mdi-lock"
                                        :type="showOldPassword ? 'text' : 'password'" 
                                        :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="showOldPassword = !showOldPassword"
                                        v-model="oldPassword"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>

                                <validation-provider
                                    :rules="{required: true}"
                                    v-slot="{errors}"
                                    name="Nova senha"
                                >
                                    <v-text-field color="primary"
                                        label="Nova senha"
                                        prepend-icon="mdi-lock-reset"
                                        :type="showNewPassword ? 'text' : 'password'" 
                                        :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="showNewPassword = !showNewPassword"
                                        v-model="newPassword"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </validation-provider>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="pa-4">
                            <v-spacer></v-spacer>

                            <v-btn @click="closePasswordDialog"
                                color="primary"
                                class="mr-3"
                                text
                            >
                                Cancelar
                            </v-btn>

                            <v-btn type="submit"
                                key="changePassword"
                                color="primary"
                                :loading="loading"
                            >
                                Alterar senha
                            </v-btn>
                        </v-card-actions>
                    </form>
                </validation-observer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: {
            required: true,
            type: Boolean
        },

        user: {
            required: true
        }
    },

    data() {
        return {
            oldPassword: null,
            showOldPassword: false,
            newPassword: null,
            showNewPassword: false,
            loading: false,
        }
    },

    methods: {
        closePasswordDialog() {
            this.$emit('closePasswordDialog')
            this.oldPassword = null
            this.newPassword = null
        },

        changePassword() {
            this.loading = true

            this.$http
                .patch(this.$ipUser + 'user/change-password', {
                    email: this.user.email,
                    password: this.oldPassword,
                    newPassword: this.newPassword
                })
                .then(res => {
                    // MSG0019 => 'Senha alterada com sucesso!'
                    if (res.data.cod === 'MSG0019') {
                        this.loading = false
                        this.closePasswordDialog()
                        this.$toast.success(res.data.msg)
                    } else {
                        this.loading = false
                        this.$toast.error(res.data.msg)
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$fnError(err)
                })
        }
    }
}
</script>

<style>

</style>